var Params = {
  RESET_PASSWORD: "/api/resouces/check-email",
  PROGRAMME_REQUIREMENT: "/api/lookup/entryQualification/all",
  CURRENT_INTAKE: "/api/lookup/config/initial_data",
  COUNTRY_LIST: "/api/lookup/country/all",
  REGION_LIST: "/api/lookup/region/all",
  DISTRICT_LIST: "/api/lookup/district",
  PRIMARY_EDUCATION: "/api/primarySchool",
  CHANGE_STAGE: "/api/applStage/next/",
  ADD_PRIMARY_EDUCATION: "/api/primarySchool/add",
  EDIT_PRIMARY_EDUCATION: "/api/primarySchool/update",
  // CHECKING_ACCOUNT_EXISTENCE: "/api/lookup/necta/olevel",
  CHECKING_ACCOUNT_EXISTENCE: "/api/necta/lookup/olevel",
  CHECKING_ACCOUNT_EXISTENCE_AUTHENTICATED: "/api/necta/olevel",
  OLEVEL_LIST: "/api/nectadata/olevel/",

  ADD_HIGH_SCHOOL_EDUCATION: "/api/lookup/necta/alevel",
  ADD_HIGH_SCHOOL_EDUCATION_AUTHENTICATED: "/api/necta/alevel",
  ALEVEL_LIST: "/api/nectadata/alevel/",
  REQUEST_NECTA_DATA: "/api/resources/necta-data",
  //College Information
  GET_COLLEGE_LIST: "/api/college/list_by_profile_id",
  SUBMIT_OUT_DATA: "/api/out/particulars",
  SUBMIT_AVN_DATA: "/api/nacte/particulars",
  SUBMIT_COLLEGE_DATA: "/api/college/save",

  //applying programs
  // CAMPUS_LIST: "/api/lookup/campus/list", // Remove the word lookup
  CAMPUS_LIST: "/api/campus/list",
  CAMPUS_PROGRAM_LIST: "/api/lookup/campus/programs",
  ADD_PROGRAM: "/api/appliedProgram/create",
  GET_APPLIED_PROGRAM: "/api/appliedProgram/query/",
  ACCOUNT_RESET_REQUEST: "/api/applicant/indexnoyear/details",
  CONTROL_NUMBER: "/api/gepg/queryControlNo",

  USER_LOGIN: "/api/authenticate",
  USER_ACCOUNT: "/api/applicant/account/",

  //next of kin
  ADD_NEXTOFKIN: "/api/nextOfKin/create",
  EDIT_NEXTOFKIN: "/api/nextOfKin/update",
  NEXT_OF_KIN_LIST: "/api/nextOfKin/query/",

  //submit application and preselectiom
  SUBMIT_APPLICATION: "/api/preselection/run",

  //reset programme
  CHANGE_PROGRAMME: "/api/appliedProgram/reset",

  //confirmation
  CONFIRMATION_STATUS: "",

  //final selection
  SELECTION_RESULTS: "/api/staff/selection/results",

  //TCU API
  REQUEST_TCU_CONFIRMATION_CODE: "/api/tcu/request_confimation_code",
  PERFORM_TCU_CONFIRMATION: "/api/tcu/confirm_admission",
  CHECK_APPLICANT_STATUS_TCU: "/api/tcu/status",
  SUBMIT_APPLICANT_TCU: "/api/tcu/add_applicants",
  PERFORM_TCU_CANCELLATION: "/api/tcu/rejectAdmission",
  CANCEL_REJECTION: "/api/tcu/restore-rejected-admission",
  PERFORM_TCU_UNCONFIRMATION: "/api/tcu/un_Confirm_admission",
};

export default Params;
